import { MetaHead } from "@modules/shared/components";
import "@styles/katex.min.css";
import "@styles/global.scss";
import "@styles/style.css";
import { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GameplaySkeleton } from "@modules/shared/components/GameplaySkeleton";

import "react-loading-skeleton/dist/skeleton.css";
import "tailwindcss/tailwind.css";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import "react-spring-bottom-sheet/dist/style.css";
import dynamic from "next/dynamic";
import { Workbox } from "workbox-window";
import { MixpanelEvent } from "@modules/home/components/MixpanelEvent";
import { PushNotifications } from "@capacitor/push-notifications";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Analytics } from "@vercel/analytics/react";

const SpeedInsights = dynamic(
    () =>
        import("@vercel/speed-insights/react").then((mod) => mod.SpeedInsights),
    { ssr: false },
);

import { ScreenOrientation } from "@capacitor/screen-orientation";

import Script from "next/script";
import * as fbq from "@helpers/fpixel";

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (search, replacement) {
        var target = this;
        let finalSearch = search;

        // Check if search is a string and needs escaping
        if (typeof search === "string") {
            finalSearch = new RegExp(
                search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                "g",
            );
        }

        // Use the RegExp directly if it's already a RegExp object
        return target.replace(finalSearch, replacement as any);
    };
}

const AppWrapper = dynamic(
    () => import("@helpers/context").then((mod) => mod.AppWrapper),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);

const UseGlobalGameplayWrapper = dynamic(
    () =>
        import("@modules/home/view/activity/Components/Progression/State").then(
            (mod) => mod.UseGlobalGameplayWrapper,
        ),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);

const RouteGuard = dynamic(
    () =>
        import("@modules/shared/components/RouteGuard").then(
            (mod) => mod.RouteGuard,
        ),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);

// const SupportGuard = dynamic(
//     () => import("@modules/shared/components/SupportGurad"),
//     {
//         ssr: false,
//     },
// );

const FPSDisplay = dynamic(
    () => import("@modules/shared/components/FPSDisplay"),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);
const RecordEvents = dynamic(
    () => import("@modules/shared/components/RecordEvents"),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);
const InternetGurad = dynamic(
    () => import("@modules/shared/components/InternetGurad"),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);
import SplashScreen from "@modules/shared/components/SplashScreen";
// const SplashScreen = dynamic(
//     () => import("@modules/shared/components/SplashScreen"),
//     {
//         ssr: false,
//         loading: () => <GameplaySkeleton />,
//     },
// );

/* const GameplaySkeleton = dynamic(
    () =>
        import("@modules/shared/components/GameplaySkeleton").then(
            (module) => module.GameplaySkeleton,
        ),
    {
        ssr: false,
        loading: () => <p>Loading...</p>,
    },
); */

const ApolloProviderApp = dynamic(
    () => import("@modules/shared/components/ApolloProviderApp"),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);

const FramerLazyMotion = dynamic(
    () => import("@modules/shared/components/FramerLazyMotion"),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);

const PullToRefreshWrapper = dynamic(
    () => import("@helpers/PullToRefreshWrapper"),
    {
        ssr: false,
        loading: () => <GameplaySkeleton />,
    },
);

import { captureException } from "@sentry/nextjs";
import { isIOSVersionAtLeast13_4, isISOApp, isNativeApp } from "@utils/helpers";
import {
    getStudentFromStorage,
    setAccessTokentoCookie,
} from "@helpers/migrateLogin";
import { dumpLogs } from "@modules/home/hooks/dumpLogs";
import axios from "axios";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false };
    }
    // this is called when an error is thrown
    // uncommenting this will cause state update i.e rerender of app
    // currently it is causing infinite rerendering, hence crashing app
    // static getDerivedStateFromError(error) {
    //     return { hasError: true };
    // }
    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        console.log("This is component error", { error, errorInfo });
        console.error({ error, errorInfo });
        captureException({
            error,
            errorInfo,
            href: window.location.href,
        });
    }
    render() {
        return this.props?.children;
    }
}

let isAudioPlayed = false;
let isVideoPlayed = false;
let isVideo2Played = false;
export const blessCommonVideoComponent = () => {
    if (isVideoPlayed) return;
    isVideoPlayed = true;
    const video = document.getElementById("commonVideoComponent");
    video?.pause();

    const video2 = document.getElementById("storyAvatarComponent");
    video2?.pause();

    const video3 = document.getElementById("story_background_video");
    video3?.pause();

    const div = document.getElementsByClassName(
        "bottomSheetDialog quiz-header-container",
    );
    if (div.length > 2) return;
    if (video?.src && video?.autoplay) {
        video?.play();
    }
    if (video2?.src && video2?.autoplay) {
        video2?.play();
    }
    if (video3?.src && video3?.autoplay) {
        video3?.play()?.catch(console.log);
    }
};

export const blessCommonAudioComponent = () => {
    // console.log('called here, playing')
    if (isAudioPlayed) return;
    isAudioPlayed = true;
    const audio = document.getElementById("commonSoundComponent");
    audio?.load();
    audio?.play()?.catch(() => {});
    audio?.pause();

    const sAudio = document.getElementById("commonStorySoundComponent");
    sAudio?.load();
    sAudio?.play()?.catch(() => {});
    sAudio?.pause();

    const div = document.getElementsByClassName(
        "bottomSheetDialog quiz-header-container",
    );
    if (div.length > 2) return;
    if (!audio?.src?.includes("1sec")) {
        audio?.play().catch(console.log);
    }
    if (!sAudio?.src?.includes("1sec")) {
        sAudio?.play().catch(console.log);
    }
};

function SetsGamify({ Component, pageProps }: AppProps): JSX.Element {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const workbox: any = useRef(null);
    const [userHasInteracted, setUserHasInteracted] = useState(false);
    const [isAppActive, setIsAppActive] = useState(true);
    const currentApp = isNativeApp();

    // UseEffect for Fpixel
    useEffect(() => {
        fbq.pageview();

        const handleRouteChange = () => {
            fbq.pageview();
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        getStudentFromStorage();
    }, []);

    useEffect(() => {
        if (currentApp) {
            App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
                const slug = event.url.split(".ai").pop();
                const slug1 = event.url.split("/home").pop();
                if (slug && !slug.includes("http")) {
                    router.push(slug);
                    return;
                }
                if (slug1 && !slug1.includes("http")) {
                    router.push(`/home/${slug1}`);
                }
            });
            ScreenOrientation.lock({ orientation: "portrait" });
        }
    }, []);

    useEffect(() => storePathValues, [router.asPath]);
    function storePathValues() {
        const storage = window.sessionStorage;
        if (!storage) return;
        // Set the previous path as the value of the current path.
        const prevPath = storage.getItem("currentPath") ?? "";
        storage.setItem("prevPath", prevPath);
        // Set the current path value by looking at the browser's location object.
        storage.setItem("currentPath", window.location.pathname);
    }

    useEffect(() => {
        Router.events.on("routeChangeStart", () => {
            setIsLoading(true);
        });
        Router.events.on("routeChangeComplete", () => {
            setIsLoading(false);
        });
        Router.events.on("routeChangeError", () => {
            setIsLoading(false);
        });
    }, [Router]);

    useEffect(() => {
        const addListeners = async () => {
            await PushNotifications.addListener("registration", (token) => {
                console.info("Registration token: ", token.value);
            });

            await PushNotifications.addListener("registrationError", (err) => {
                console.error("Registration error: ", err.error);
            });

            await PushNotifications.addListener(
                "pushNotificationReceived",
                (notification) => {
                    console.log("Push notification received: ", notification);
                },
            );

            await PushNotifications.addListener(
                "pushNotificationActionPerformed",
                (notification) => {
                    console.log(
                        "Push notification action performed",
                        JSON.stringify(notification),
                        notification.actionId,
                        notification.inputValue,
                    );
                    // Extract the URL from the notification data
                    const url = notification.notification.data.url;

                    // Navigate to the specified URL if it exists
                    if (url) {
                        router.push(url);
                    }
                },
            );
        };

        const registerNotifications = async () => {
            let permStatus = await PushNotifications.checkPermissions();
            // console.log("permStatus", JSON.stringify(permStatus));

            if (permStatus.receive === "prompt") {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive === "granted") {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== "granted") {
                throw new Error("User denied permissions!");
            }

            await PushNotifications.register();
        };

        const getDeliveredNotifications = async () => {
            // const notificationList =
            await PushNotifications.getDeliveredNotifications();
            // console.log('delivered notifications', JSON.stringify(notificationList));
        };

        const isPushNotificationsAvailable =
            Capacitor.isPluginAvailable("PushNotifications");
        if (isPushNotificationsAvailable && !isISOApp()) {
            registerNotifications();
            addListeners();
            getDeliveredNotifications();
        }
    }, []);

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            if (
                true
                // String(process.env.NEXT_PUBLIC_REMOVE_SERVICE_WORKER) == "true"
            ) {
                navigator.serviceWorker.ready.then((registration) => {
                    if (registration.unregister) registration.unregister();
                });
            } else {
                const wb = new Workbox("/service-worker.js");
                wb.register().then(
                    function (registration: any) {
                        console.log(
                            "Service Worker registration successful with scope: ",
                            registration.scope,
                        );
                        wb.messageSW({ type: "DOM_LOADED" });
                    },
                    function (err: any) {
                        console.log(
                            "Service Worker registration failed: ",
                            err,
                        );
                    },
                );
                workbox.current = wb;

                wb.messageSW({ type: "DOM_LOADED" });
                wb.addEventListener("message", (event) => {
                    const { type = "error", error } = event.data;
                    if (type == "error") {
                        captureException(error);
                        return;
                    }
                });
            }
        }
        // setIsMounted(true);

        // import("react-microsoft-clarity").then((microsoftClarity) => {
        //     microsoftClarity.clarity.init("hkn91a6niw");
        // });

        function onInteract() {
            setUserHasInteracted(true);
            window.removeEventListener("click", onInteract);
            window.removeEventListener("touchstart", onInteract);
            window.removeEventListener("pointerdown", onInteract);
            blessCommonAudioComponent();
        }
        window.addEventListener("click", onInteract);
        window.addEventListener("touchstart", onInteract);
        window.addEventListener("pointerdown", onInteract);
        document.addEventListener(
            "wheel",
            function (e) {
                if (e.ctrlKey) {
                    e.preventDefault();
                }
            },
            {
                passive: false,
            },
        );
        function handleBeforeUnload() {
            if (workbox.current) {
                workbox.current.messageSW({ type: "DOM_UNLOADED" });
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("click", onInteract);
            window.removeEventListener("touchstart", onInteract);
            window.removeEventListener("pointerdown", onInteract);
            workbox.current?.removeEventListener?.("message");
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const [deviceReady, setDeviceReady] = useState(false);
    useEffect(() => {
        async function loadMixpanel() {
            const student = JSON.parse(getStudentFromStorage() || "{}");
            try {
                MixpanelEvent.init();
                if (student?.id) {
                    MixpanelEvent.identify(
                        `${student.mobile}_${student?.name
                            ?.toLowerCase()
                            .replace(/ /g, "_")}_${student?.id}`,
                    );
                    setAccessTokentoCookie();
                }
                dumpLogs({
                    action: "mixpanel_init",
                    ...student,
                });
                return addToCacheWithMessage({
                    type: "COMPONENT_PRECACHE_DELETE",
                });
            } catch (error) {
                captureException(error);
                dumpLogs({
                    action: "mixpanel_init_fail",
                    ...student,
                    error,
                });
                console.error("Error loading Mixpanel:", error);
            }
        }
        // Call the function to load Mixpanel
        loadMixpanel();

        axios.interceptors.request.use(
            function (config) {
                if (config) {
                    const user = JSON.parse(getStudentFromStorage() || "{}");
                    const pino_logger_props = {
                        user: user,
                        platform: Capacitor.getPlatform(),
                        client: window.location.href,
                    };

                    if (!config.headers) {
                        config.headers = {};
                    }

                    config.headers["pino-logger-props"] =
                        JSON.stringify(pino_logger_props);
                }

                return config;
            },
            function (error) {
                return Promise.reject(error);
            },
        );
    }, []);

    const { metadataTags } = pageProps;

    const addAssetsToSW = async (assets: string[], isDelete = false) => {
        if (workbox?.current) {
            workbox.current.messageSW({
                type: "PRECACHE",
                payload: assets,
                isDelete,
            });
        }
    };

    const addToCacheWithMessage = async (data: any) => {
        if (workbox?.current) {
            workbox.current.messageSW(data);
        }
    };

    return (
        <>
            <Script
                id="fb-pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
                }}
            />
            {showSplash && (
                <SplashScreen
                    showSplash={showSplash}
                    setShowSplash={setShowSplash}
                    metadataTags={metadataTags}
                />
            )}
            <MetaHead metadataTags={metadataTags} />
            {isLoading && <GameplaySkeleton />}
            <FPSDisplay />
            <RecordEvents />
            <ApolloProviderApp>
                {/* Moved to specfic component where it is used to reduce bundle size */}
                {/* <DndProvider
                    backend={is_mobile ? TouchBackend : HTML5Backend}
                    {...(is_mobile ? { options: { delay: 200 } } : {})}
                > */}
                {/* <DndPreview /> */}
                <AppWrapper>
                    <UseGlobalGameplayWrapper
                        addToCacheWithMessage={addToCacheWithMessage}
                    >
                        <ErrorBoundary>
                            <RouteGuard>
                                <InternetGurad>
                                    {/* <SupportGuard> */}
                                    <FramerLazyMotion>
                                        <PullToRefreshWrapper>
                                            <ToastContainer />
                                            <Analytics />
                                            {isIOSVersionAtLeast13_4() && (
                                                <SpeedInsights
                                                    route={router.pathname}
                                                />
                                            )}
                                            <Component
                                                {...pageProps}
                                                showSplash={showSplash}
                                                addAssetsToSW={addAssetsToSW}
                                                addToCacheWithMessage={
                                                    addToCacheWithMessage
                                                }
                                                userHasInteracted={
                                                    userHasInteracted
                                                }
                                            />
                                        </PullToRefreshWrapper>
                                    </FramerLazyMotion>
                                    {/* </SupportGuard> */}
                                </InternetGurad>
                            </RouteGuard>
                        </ErrorBoundary>
                    </UseGlobalGameplayWrapper>
                </AppWrapper>
                {/* </DndProvider> */}
            </ApolloProviderApp>
        </>
    );
}

export default SetsGamify;
