import mixpanel from "mixpanel-browser";
import { dumpLogs, loggedSession } from "../hooks/dumpLogs";
import { Capacitor } from "@capacitor/core";
import { getStudentFromStorage } from "@helpers/migrateLogin";
class MixpanelEventClass {
    student = {};
    mathai_platform = "";
    activity_type = "CHALLENGE";
    init() {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
            persistence: "localStorage",
            ignore_dnt: true,
            batch_requests: true,
            api_host: process.env.NEXT_PUBLIC_MIXPANEL_PROXY_URL,
        });
        this.mathai_platform = Capacitor.getPlatform();
    }
    identify(userId: string) {
        const student = JSON.parse(getStudentFromStorage() || "{}");
        if (student) {
            const other = JSON.parse(student.other || "{}");
            this.student = {
                id: student.id,
                name: student.name,
                mobile: student.mobile,
                grade: student.class_name,
                type: student.type,
                parent_name: other?.parent_name,
                email: student.email,
            };
        }

        mixpanel.identify(userId);
        let other = student.other || {};
        try {
            other = JSON.parse(student.other);
        } catch {}
        mixpanel.people.set(
            {
                name: student.name,
                email: student.email,
                phone: student.mobile,
                grade: student.class_name,
                type: student.type,
                gender: student.gender,
                access_type: student.access_type,
                parent_name: other?.parent_name,
                maths_feeling: other?.maths_feeling,
                pervious_marks: other?.pervious_marks,
                // language: other?.language,
                competition_prep: other?.competition_prep,
                goals: other?.goals,
                goals_commitment: other?.goals_commitment,
                profile_picture: other.profile_picture,
            },
            (...e) => {
                if (e[0] !== 1)
                    dumpLogs({
                        action: "mixpanel_people_set_fail",
                        ...student,
                    });
                else
                    dumpLogs({
                        action: "mixpanel_people_set",
                        ...student,
                    });
            },
        );
    }

    defaultEvents = { utm_source: "" };
    track(event: string, data?: any) {
        const logged_session_id = loggedSession.logged_session_id;
        mixpanel.track(
            event,
            {
                ...this.defaultEvents,
                ...data,
                ...this.student,
                mathai_platform: this.mathai_platform,
                ...(logged_session_id ? { logged_session_id } : {}),
                activity_type: this.activity_type,
            },
            (...e) => {
                if (e[0] !== 1)
                    dumpLogs({
                        action: "mixpanel_track_fail",
                        ...this.student,
                        event,
                        data,
                    });
            },
        );
    }
}

export const MixpanelEvent = new MixpanelEventClass();
